<template>
  <div class="row">
    <div class="col-12 col-lg-8 c g">
    <div class="card card-body" v-if="!loading">
        اختر ملف الشهادات (اكسل .xlsx)
        <br><br>
        <input
            id="ingredient_file"
            type="file" accept=".pdf" @change="listenFile()"
        />
    </div>
    <div class="card card-body text-center" v-if="loading">
        <img :src="require('@/assets/images/results-ai.gif')" width="150" class="c" alt="">
        <h5><img :src="require('@/assets/images/loading.svg')" width="25" class="c" alt=""> جاري التحليل...</h5>
        <h5 class="text-primary">
            &nbsp; <span class="statusC" v-html="status"></span>
        </h5>
        <div class="col-12 text-center" style="position: relative; top: 5px">
            <div class="progress" v-if="loading" style="height: 20px; max-width: 300px; margin: 0px auto; border-radius: 5px !important">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="`width: ${percent}%; border-radius: 5px !important`" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
        <br>
        <small class="text-muted">رقم العملية #{{ process_id }}</small>
        <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
        </div>
    </div>
  </div>
    <div class="col-12" v-if="!loading && result.arr">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="card card-body">
                    <div id="chartContainer"></div>
                    <br><br><br><br>
                    <br><br><br><br>
                    <br><br><br><br>
                    <br><br><br><br>
                    <br><br><br><br>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card card-body">
                    <div id="chartContainer2"></div>
                    <br><br><br><br>
                    <br><br><br><br>
                    <br><br><br><br>
                    <br><br><br><br>
                    <br><br><br><br>
                </div>
            </div>
        </div>
        <br>
        <app-collapse>
            <app-collapse-item title="📈👥 نتائج الطلاب" v-show="true" id="s1">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <th>
                                م
                            </th>
                            <th>
                                الاسم
                            </th>
                            <th>
                                رقم الهوية
                            </th>
                            <th>
                                الصف
                            </th>
                            <th>
                                الفصل
                            </th>
                            <th>
                                اجمالي الدرجات
                            </th>
                            <th>
                                ترتيب الصف
                            </th>
                            <th>
                                ترتيب الفصل
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(student, m) in result.arr" :key="student.number">
                                <td>
                                    {{ m+1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.number }}
                                </td>
                                <td>
                                    {{ student.classname }}
                                </td>
                                <td>
                                    {{ student.classroom }}
                                </td>
                                <td>
                                    {{ student.subjectsTotalDegrees }}
                                </td>
                                <td>
                                    {{ student.rankInGrade }}
                                </td>
                                <td>
                                    {{ student.rankInClass }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-collapse-item>
            <br>
            <b-modal id="view-students" title="عرض الطلاب" hide-footer size="lg">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered">
                        <thead>
                            <th>
                                م
                            </th>
                            <th>
                                الطالب
                            </th>
                            <th>
                                رقم الهوية
                            </th>
                            <th>
                                الصف
                            </th>
                            <th>
                                الفصل
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(student, m) in viewstudents" :key="m+1+student.number">
                                <td>
                                    {{ m+1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.number }}
                                </td>
                                <td>
                                    {{ student.classname }}
                                </td>
                                <td>
                                    {{ student.classroom }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-modal>
            <app-collapse-item title="📚 احصائيات المواد" v-show="true" id="s2">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <th>
                                ترتيب المادة
                            </th>
                            <th>
                                المادة
                            </th>
                            <th>
                                عدد الناجحين
                            </th>
                            <th>
                                عدد المتفوقين
                            </th>
                            <th>
                                عدد الضعفاء
                            </th>
                            <th>
                                عدد المقبولين
                            </th>
                            <th>
                                عدد الراسبين
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(subject, m) in result.subjects" :key="subject.number">
                                <td>
                                    {{ m+1 }}
                                </td>
                                <td>
                                    {{ subject.name }}
                                </td>
                                <td>
                                    <a href="javascript:;" @click="viewstudents=subject.success" v-b-modal.view-students>
                                        {{ subject.success.length }}
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="viewstudents=subject.good" v-b-modal.view-students>
                                        {{ subject.good.length }}
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="viewstudents=subject.bad" v-b-modal.view-students>
                                        {{ subject.bad.length }}
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="viewstudents=subject.accepted" v-b-modal.view-students>
                                        {{ subject.accepted.length }}
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="viewstudents=subject.fail" v-b-modal.view-students>
                                        {{ subject.fail.length }}
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-collapse-item>
            <br>
            <b-modal id="view-subjects" title="عرض المواد" hide-footer size="lg">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered">
                        <thead>
                            <th>
                                م
                            </th>
                            <th>
                                المادة
                            </th>
                            <th>
                                الدرجة
                            </th>
                            <th>
                                التقدير
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(student, m) in viewstubjects" :key="m+1+student.number">
                                <td>
                                    {{ m+1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.degree }}
                                </td>
                                <td>
                                    {{ student.grade }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-modal>
            <app-collapse-item title="👎 الطلاب الضعفاء" v-show="true" id="s3">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <th>
                                م
                            </th>
                            <th>
                                الطالب
                            </th>
                            <th>
                                رقم الهوية
                            </th>
                            <th>
                                الصف
                            </th>
                            <th>
                                الفصل
                            </th>
                            <th>
                                المواد الضعيف فيها
                            </th>
                        </thead>
                        <tbody v-if="result.weaks">
                            <tr v-for="(student, m) in result.weaks" :key="student.number+m*33">
                                <td>
                                    {{ m+1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.number }}
                                </td>
                                <td>
                                    {{ student.classname }}
                                </td>
                                <td>
                                    {{ student.classroom }}
                                </td>
                                <td>
                                    <a href="javascript:;" @click="viewstubjects=student.subjects" v-b-modal.view-subjects>
                                        {{ student.subjects.length }}
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-collapse-item>
        </app-collapse>
    </div>
  </div>
</template>

<script>
import CanvasJS from '@canvasjs/charts';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BFormCheckbox, VBModal, BModal, } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            result: {},
            viewstudents: [],
            viewstubjects: [],
            user: JSON.parse(localStorage.getItem('user')),
            file: null,
            loading: false,
            status: "",
            percent: "",
            process_id: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            })
        }
    },
    created(){
        if(!checkPer("results")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
    },
    methods: {
        listenFile() {
            var g = this;
            g.process_id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
            g.loading = true;
            var Upload = function (file) {
                this.file = file;
            };
            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.user.jwt);

                $.ajax({
                type: "POST",
                url: api + '/user/general/upload-tmp-files',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    // your callback here
                    $("#progress-wrp").fadeOut("slow");
                    if(data.status != 100){
                       return alert(data.response, 200)
                    }
                    g.file = data.response
                    g.step = 4;
                    g.process()
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };

            Upload.prototype.progressHandling = function (event) {
                var percent = 0;
                var position = event.loaded || event.position;
                var total = event.total;
                var progress_bar_id = "#progress-wrp";
                if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
                }
                // update progressbars classes so it fits your code
                $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
                $(progress_bar_id + " .status").text(percent + "%");
            };
            $("#progress-wrp").fadeIn("slow");
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);

            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            upload.doUpload();
        },
        process(){
            var g = this;
            $.ajax({
                url: api + '/user/results/ai-process-status',
                method: 'POST',
                data: {  jwt: this.user.jwt, process_id: g.process_id }, // Your POST data
                xhrFields: {
                    // Important for streaming
                    onprogress: function(e) {
                        // This function will be called as data arrives
                        var chunk = e.currentTarget.responseText;
                        // Process the chunk of data received
                        try{
                            if(chunk.split("\n")[chunk.split("\n").length - 1]?.includes("{")){
                                g.status = JSON.parse(chunk.split("\n")[chunk.split("\n").length - 1]).response;
                                g.percent = JSON.parse(chunk.split("\n")[chunk.split("\n").length - 1]).percent;
                            }
                        }catch(e){}
                    }
                },
                success: function(data) {
                },
                error: function(xhr, status, error) {
                    console.error('Error:', error);
                }
            });
            $.post(api + '/user/results/ai', {
                jwt: this.user.jwt,
                file: g.file.split("/tmp/")[1],
                process_id: g.process_id
            })
            .then(function(r){
                g.loading = false;
                if(r.status == 100){
                    g.result = r.response;
                    function compare( a, b ) {
                        if ( a.subjectsTotalDegrees < b.subjectsTotalDegrees ){
                            return 1;
                        }
                        if ( a.subjectsTotalDegrees > b.subjectsTotalDegrees ){
                            return -1;
                        }
                        return 0;
                    }
                    function compare2( a, b ) {
                        if ( a.rank < b.rank ){
                            return 1;
                        }
                        if ( a.rank > b.rank ){
                            return -1;
                        }
                        return 0;
                    }
                    g.result.arr = g.result.arr.map(function(x){
                        if(!x.subjectsTotalDegrees == "" || !x.subjectsTotalDegrees){
                            x.subjectsTotalDegrees = 0;
                            x.subjects.forEach(function(a){
                                x.subjectsTotalDegrees = x.subjectsTotalDegrees + new Number(a.degree ?? 0);
                            })
                        }
                        return x;
                    })
                    g.result.arr.sort( compare );
                    g.result.subjects.sort( compare2 );
                    g.result.classes.sort( compare2 );
                    g.result.weaks = [];
                    g.result.arr.forEach(function(a){
                        var add = false;
                        a.subjects.forEach(function(x){
                            if(x.grade.includes('ضعيف') || x.grade.includes('مقبول') || x.grade.includes('راسب')){
                                add = true;
                            }
                        })
                        if(add){
                            a.subjects = a.subjects.filter(function(x){
                                if(x.grade.includes('ضعيف') || x.grade.includes('مقبول') || x.grade.includes('راسب')){
                                    return x;
                                }
                                return false;
                            })
                            g.result.weaks.push(a)
                        }
                    })
                    g.result = JSON.parse(JSON.stringify(g.result))
                    setTimeout(() => {
                        var chart = new CanvasJS.Chart("chartContainer", {
                            theme: "light1", // "light2", "dark1", "dark2"
                            animationEnabled: false, // change to true		
                            title:{
                                text: "📚 أفضل المواد"
                            },
                            data: [
                            {
                                type: "column",
                                dataPoints: g.result.subjects.map(function(x){
                                    return {
                                        label: x.name,
                                        y: x.success.length
                                    }
                                })
                                // [
                                //     { label: "apple",  y: 10  },
                                //     { label: "orange", y: 15  },
                                //     { label: "banana", y: 25  },
                                //     { label: "mango",  y: 30  },
                                //     { label: "grape",  y: 28  }
                                // ]
                            }
                            ]
                        });
                        chart.render();
                        var chart = new CanvasJS.Chart("chartContainer2", {
                            theme: "light1", // "light2", "dark1", "dark2"
                            animationEnabled: false, // change to true		
                            title:{
                                text: "📈 أفضل الصفوف"
                            },
                            data: [
                            {
                                type: "pie",
                                dataPoints: g.result.classes.map(function(x){
                                    return {
                                        label: x.name,
                                        y: x.success.length
                                    }
                                })
                                // [
                                //     { label: "apple",  y: 10  },
                                //     { label: "orange", y: 15  },
                                //     { label: "banana", y: 25  },
                                //     { label: "mango",  y: 30  },
                                //     { label: "grape",  y: 28  }
                                // ]
                            }
                            ]
                        });
                        chart.render();
                    }, 500)
                    setTimeout(() => {
                        $('#s1 [aria-controls]').click()
                        setTimeout(() => {
                            $('#s2 [aria-controls]').click()
                        }, 500);
                        setTimeout(() => {
                            $('#s3 [aria-controls]').click()
                        }, 500);
                    }, 50);
                }else{
                    alert(r.response, 200)
                }
            })
            .fail(function(){
                g.loading = false;
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
.table-responsive{
   min-height: 0px !important;
}
</style>